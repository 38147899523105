<template>
  <div class="container-fluid">
    <div id="load-spinner">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div id="wash-subs-form">
      <div class="row">
        <div class="col-lg-12">
          <div
            class="
              d-flex
              flex-wrap
              align-items-center
              justify-content-between
              mb-3
            "
          >
            <div
              class="
                d-flex
                align-items-center
                justify-content-between
                text-padding-mobile
              "
            >
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb p-0 mb-0">
                  <li class="breadcrumb-item">
                    <router-link :to="{ name: 'layout.washsubscription' }"
                      >Wash Subscriptions</router-link
                    >
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Add Wash Subscription
                  </li>
                </ol>
              </nav>
            </div>
            <router-link
              :to="{ name: 'layout.washsubscription' }"
              class="
                btn btn-secondary btn-sm
                d-flex
                align-items-center
                justify-content-between
                ml-2
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="ml-2">Back</span>
            </router-link>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <div class="card">
            <div class="col mb-3">
              <h5 class="font-weight-bold mt-3 mb-3">Customer Details</h5>

              <div class="d-flex justify-content-between">
                <label
                  for="inputState"
                  class="form-label font-weight-bold text-muted"
                  >Customer *</label
                >
                <router-link :to="{ name: 'customer.customeradd' }">
                  <!-- <img class="mb-1" :src="plusSvg" /> -->
                  <input
                    class="btn btn-success order-add btn-sm"
                    id="order-now"
                    type="button"
                    value="Add"
                  />
                </router-link>
              </div>

              <multiselect
                class="dropdown-css"
                v-model="userId"
                :options="users"
                value="user"
                :custom-label="getCustomCustomer"
                track-by="id"
                placeholder="Select Customer"
                @search-change="handleSearchCustomer"
                :max="users.length"
                @select="onChangeCustomer"
                @remove="removeCustomer"
                :searchable="true"
                :internal-search="false"
                ><span slot="noResult"> No Customer found.</span>
              </multiselect>

              <label
                v-if="isCustomer"
                class="text-danger col-form-label-sm mt-1 ml-1"
                >Customer Not Selected</label
              >
            </div>

            <div class="col mb-3" v-if="userId">
              <div class="d-flex justify-content-between">
                <label
                  for="car-model"
                  class="form-label font-weight-bold text-muted"
                  >Car *</label
                >
                <router-link :to="{ path: '/car/add/' + this.customerPhone }">
                  <!-- <img class="mb-1" :src="plusSvg" /> -->
                  <input
                    class="btn btn-success order-add btn-sm"
                    id="order-now"
                    type="button"
                    value="Add"
                  />
                </router-link>
              </div>

              <multiselect
                class="dropdown-css"
                v-model="carId"
                :options="cars"
                value="car"
                :custom-label="getCustomCar"
                track-by="id"
                placeholder="Select Car"
                @select="onChangeCar"
                @remove="removeCar"
                :searchable="true"
                :internal-search="true"
                ><span slot="noResult"> No Car found.</span>
              </multiselect>

              <label v-if="isCar" class="text-danger col-form-label-sm ml-1"
                >Car Not Selected</label
              >
            </div>
          </div>
          <div class="card desktopView" v-if="packageId">
            <div class="col-12 mb-2">
              <div class="d-flex justify-content-between mt-3 mb-3">
                <h5 class="font-weight-bold mt-3 mb-2">Voucher</h5>
              </div>
              <div class="row">
                <div class="col-6 mb-4 d-flex flex-column">
                  <multiselect
                    v-model="voucher_code"
                    :options="vouchers"
                    value="code"
                    key="code"
                    track-by="code"
                    :custom-label="getCustomVoucher"
                    :placeholder="
                      vouchers.length > 0 ? 'Select Voucher' : 'No Vouchers'
                    "
                    :multiple="false"
                    :max="vouchers.length"
                    @select="onChangeVoucher"
                    @remove="removeVoucher"
                    :searchable="true"
                    :internal-search="true"
                    :taggable="true"
                    :show-labels="false" 
                    :allow-empty="true"
                    @tag="handleVoucherCode"
                    tag-placeholder="press enter to create a voucher"
                  >
                  <template slot="clear" slot-scope="props">
                    <span class="custom__tag remove-voucher-custom">
                      <span class="custom__remove" v-if='voucher_code !== null' @click="removeVoucher(props)">x</span>
                    </span>
                  </template>
                  </multiselect>
                  <label
                v-if="isVoucher"
                class="text-danger col-form-label-sm mt-1 ml-1"
                >Voucher Not Selected</label>
              <div>
                <label
                  v-if="isVoucherApplied == true"
                  for="Text1"
                  class="text-success font-weight-bold success-msg col-form-label-sm mt-1 ml-1"
                  >{{voucherName}}</label
                >
                <label
                  v-else-if="isVoucherApplied == false"
                  for="Text1"
                  class="text-danger success-msg col-form-label-sm mt-1 ml-1"
                  >{{ reason }}</label>
              </div>
                </div>
                <div class="col-6">
                  <button
                    :disabled="!isVoucherApplicable === false ? true : false"
                    class="btn btn-sm btn-success voucher"
                    @click="applyVoucher"
                    id="apply-voucher"
                  >
                    Apply
                  </button>
                  <button
                    :disabled="isVoucherApplicable === false ? true : false"
                    class="btn btn-sm btn-danger ml-1 voucher"
                    @click="removeVoucher"
                    id="remove-voucher"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card desktopView" v-if="customerCarId && packageId">
            <div class="col mb-3">
              <h5 class="font-weight-bold mt-3 mb-3">Total Info</h5>
              <div class="table-responsive">
                <table class="table table-borderless mb-0">
                  <tbody v-if="defaultTotal === false && finalDiscountTotal.length === 0">
                    <tr
                      v-if="this.finalTotal.totalExclTax"
                      class="white-space-no-wrap"
                    >
                      <td class="text-muted pl-0">Sub Total</td>
                      <td class="text-monospace">
                        {{
                          this.finalTotal.totalExclTax.amount.toLocaleString(
                            "en-US"
                          ) +
                          " " +
                          this.finalTotal.totalExclTax.currency
                        }}
                      </td>
                    </tr>
                    <tr class="white-space-no-wrap">
                      <td class="text-muted pl-0">Discount</td>
                      <td class="text-monospace" style="width: 3rem">
                        <input
                          min="0"
                          class="custom-input"
                          type="number"
                          id="discountAmount"
                          onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                          v-model="discountAmount"
                          placeholder="0"
                          :maxlength="6"
                          @input="handleDiscountAmount($event)"
                        />
                      </td>
                    </tr>
                    <tr
                      v-if="this.afterDiscountTotal"
                      class="white-space-no-wrap"
                    >
                      <td class="text-muted pl-0">Total (After Discount)</td>
                      <td class="text-monospace">
                        {{
                          this.afterDiscountTotal.toLocaleString("en-US") +
                          " " +
                          this.finalTotal.totalExclTax.currency
                        }}
                      </td>
                    </tr>
                    <tr v-if="this.finalTotal.tax" class="white-space-no-wrap">
                      <td class="text-muted pl-0">Tax</td>
                      <td class="text-monospace">
                        {{
                          this.tax.toLocaleString("en-US") +
                          " " +
                          this.finalTotal.tax.currency
                        }}
                      </td>
                    </tr>
                    <tr
                      v-if="this.finalTotal.totalInclTax"
                      class="white-space-no-wrap"
                    >
                      <td class="text-muted pl-0">Final payable</td>
                      <td class="text-monospace">
                        {{
                          this.totalTaxInclude.toLocaleString("en-US") +
                          " " +
                          this.finalTotal.totalInclTax.currency
                        }}
                      </td>
                    </tr>
                  </tbody>
                   <tbody v-else>
                    <tr
                      v-if="this.finalDiscountTotal"
                      class="white-space-no-wrap"
                    >
                      <td class="text-muted pl-0">Sub Total</td>
                      <td class="text-monospace">
                        {{
                          this.finalDiscountTotal.totalBeforeDiscount.amount.toLocaleString(
                            "en-US"
                          ) +
                          " " +
                          this.finalDiscountTotal.totalBeforeDiscount.currency
                        }}
                      </td>
                    </tr>
                    <tr class="white-space-no-wrap">
                      <td class="text-muted pl-0">Discount</td>
                      <td class="text-monospace"  style="width: 3rem">
                        <input
                          min="0"
                          class="custom-input"
                          type="number"
                          id="discountAmount"
                          onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                          v-model="this.discountAmount"
                          placeholder="0"
                          :disabled="isDiscountDisabled"
                        />

                          <!-- @input="handleAfterVoucherDiscountAmount($event)" -->
                      </td>
                    </tr>
                    <tr v-if="this.finalTaxAfterToTal" class="white-space-no-wrap">
                      <td class="text-muted pl-0">Total (After Discount)</td>
                      <td class="text-monospace">
                        {{
                          this.finalTaxAfterToTal.toLocaleString("en-US") +
                          " " +
                          this.finalDiscountTotal.totalAfterDiscount.currency
                        }}
                      </td>
                    </tr>
                    <tr v-if="this.finalDiscountTotal.finalTax" class="white-space-no-wrap">
                      <td class="text-muted pl-0">Tax</td>
                      <td class="text-monospace">
                        {{
                          this.finalDiscountedTax.toLocaleString("en-US") +
                          " " +
                          this.finalDiscountTotal.finalTax.currency
                        }}
                      </td>
                    </tr>
                    <tr
                      v-if="this.finalDiscountTotal.finalTotalInclTax"
                      class="white-space-no-wrap"
                    >
                      <td class="text-muted pl-0">Total</td>
                      <td class="text-monospace">
                        {{
                          this.finalTotalInclTax.toLocaleString("en-US") +
                          " " +
                          this.finalDiscountTotal.finalTotalInclTax.currency
                        }}
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card" v-if="customerCarId">
            <div class="col mb-3">
              <h5 class="font-weight-bold mt-3 mb-3">Choose Package & Slots</h5>

              <div class="d-flex justify-content-between">
                <label
                  for="inputState"
                  class="form-label font-weight-bold text-muted"
                >
                  Building *
                </label>
              </div>

              <multiselect
                v-model="buildingId"
                :options="buildings"
                value="building"
                :custom-label="getCustomBuilding"
                track-by="id"
                placeholder="Select Building"
                @select="onChangeBuilding"
                @remove="removeBuilding"
                :searchable="true" 
                :internal-search="false" 
                @search-change="handleSearchBuilding"
              >
                <template slot="clear" slot-scope="props">
                  <div class="multiselect__clear" v-if="buildings.length" @mousedown.prevent.stop="clearAll(props.search)"></div>
                </template>
                <span slot="noResult"> No Building found.</span>
  

              </multiselect>

              <label
                v-if="isBuilding"
                class="text-danger col-form-label-sm mt-1 ml-1"
                >Building Not Selected</label
              >
            </div>

            <div class="col mb-3">
              <div class="d-flex justify-content-between">
                <label
                  for="car-model"
                  class="form-label font-weight-bold text-muted"
                >
                  Package *
                </label>
              </div>

              <multiselect
                v-model="packageId"
                :options="packages"
                value="package"
                :custom-label="getCustomPackage"
                track-by="id"
                placeholder="Select Package"
                @select="onChangePackage"
                @remove="removePackage"
                :internal-search="true"
                ><span slot="noResult"> No Package found.</span>
              </multiselect>

              <label
                v-if="isPackage"
                class="text-danger col-form-label-sm ml-1"
              >
                Package Not Selected
              </label>
            </div>
            <div class="col mb-3" v-if="packageId">
              <div class="d-flex justify-content-between">
                <label
                  for="car-model"
                  class="form-label font-weight-bold text-muted"
                >
                  Slots *
                </label>
              </div>
              <div v-for="(el, index) in slotsWeekOptions" :key="index">
                <div class="row slot-row">
                  <div class="col mb-6">
                    <multiselect
                      v-model="slotDay[index]"
                      placeholder="Slot Day"
                      label="weekDay"
                      :options="
                        weekDayOptions.filter((v) =>
                          v.weekDay == el.weekDay
                            ? true
                            : !slotDay.find((e) => e.weekDay === v.weekDay)
                        )
                      "
                      :multiple="false"
                      :taggable="false"
                      @select="(event) => onSelectedSlotDay(event, index)"
                      ><span slot="noResult"> No Data found.</span></multiselect
                    >
                  </div>
                  <div class="col mb-6 time-div">
                    <multiselect
                      v-if="slotDay[index]"
                      label="label"
                      v-model="selectedStates[index].values"
                      placeholder="Slot Time"
                      :options="getOptions(slotDay[index].id)"
                      :multiple="false"
                      :taggable="false"
                      @select="(event) => onSelectedSlotTime(event, index)"
                      ><span slot="noResult"> No Data found.</span>
                    </multiselect>
                  </div>
                </div>
                <br />
              </div>

              <!-- 
              <div class="dropdown-item"  v-for="(el, index) in slotsWeekOptions"
                :key="index">
                <multiselect 
                v-model="slotDay[index]" 
                placeholder="Country?" 
                label="weekDay" 
                :options="weekDayOptions" 
                :multiple="false" 
                :taggable="false"  
                @select="onSelectedSlotDay"></multiselect>


                <multiselect style="margin-left:20px;" 
                v-if="slotDay[index]" label="label" 
                v-model="selectedStates[index].values.id" placeholder="States?" :options="getOptions(slotDay[index].id)" :multiple="false" :taggable="false" @select="(event) => onSelectedSlotTime(event,index)"></multiselect>
              </div> -->
            </div>

            <div class="col mb-3">
              <div class="d-flex justify-content-between">
                <label
                  for="car-model"
                  class="form-label font-weight-bold text-muted"
                >
                  Parking Number *
                </label>
              </div>

              <input
                class="custom-input"
                type="text"
                value="parkingSlot"
                v-model="parkingSlot"
                @input="handleParkingSlot($event)"
              />

              <label
                v-if="isParkingSlot"
                class="text-danger col-form-label-sm ml-1"
              >
                Parking Number Required
              </label>
            </div>


            <div class="col mb-3">
              <label
                  for="inputState"
                  class="form-label font-weight-bold text-muted"
                  >Auto Charge</label>
              <b-form-checkbox
                size="lg"
                v-model="checkedAutoCharge"
                @input="handleAutoCharge"
                name="check-button"
                switch
              >
              </b-form-checkbox>
            </div>
            
            <div class="col mb-3">
              <label
                  for="inputState"
                  class="form-label font-weight-bold text-muted"
                  >Is Cash ?</label>
              <b-form-checkbox
                size="lg"
                v-model="checkedIsCash"
                @input="handleIsCash"
                name="check-button"
                switch
              >
              </b-form-checkbox>
            </div>
            
            <div class="col mb-3">
              <div class="d-flex justify-content-between">
                <label
                  for="inputState"
                  class="form-label font-weight-bold text-muted"
                  >Staff Note</label>
              </div>

              <textarea
                class="custom-input notesArea"
                rows="2"
                v-model="staffNote"
                @input="handleStaffNote($event)"
              ></textarea>
            </div>
          </div>
          <div class="card mobileView" v-if="packageId">
            <div class="col-12 mb-3">
              <div class="d-flex justify-content-between mt-3 mb-2">
                <h5 class="font-weight-bold mt-3 mb-2">Voucher</h5>
              </div>
              <div class="row mb-2">
                <div class="col-12 d-flex flex-column">
                  <multiselect
                    v-model="voucher_code"
                    :options="vouchers"
                    value="code"
                    key="code"
                    track-by="code"
                    :custom-label="getCustomVoucher"
                    :placeholder="
                      vouchers.length > 0 ? 'Select Voucher' : 'No Vouchers'
                    "
                    :multiple="false"
                    :max="vouchers.length"
                    @select="onChangeVoucher"
                    @remove="removeVoucher"
                    :searchable="true"
                    :internal-search="true"
                    :taggable="true"
                    :show-labels="false" 
                    :allow-empty="true"
                    @tag="handleVoucherCode"
                    tag-placeholder="press enter to create a voucher"
                  >
                  <template slot="clear" slot-scope="props">
                    <span class="custom__tag remove-voucher-custom">
                      <span class="custom__remove" v-if='voucher_code !== null' @click="removeVoucher(props)">x</span>
                    </span>
                  </template>
                  </multiselect>
                    <label
                      v-if="isVoucher"
                      class="text-danger col-form-label-sm mt-1 ml-1"
                      >Voucher Not Selected</label>
                    <div>
                      <label
                        v-if="isVoucherApplied == true"
                        for="Text1"
                        class="text-success font-weight-bold success-msg col-form-label-sm mt-1 ml-1"
                        >{{voucherName}}</label
                      >
                      <label
                        v-else-if="isVoucherApplied == false"
                        for="Text1"
                        class="text-danger success-msg col-form-label-sm mt-1 ml-1"
                        >{{ reason }}</label>
                    </div>
                </div>
                <div class="col-12 mt-2 mb-2">
                  <button
                    :disabled="!isVoucherApplicable === false ? true : false"
                    class="btn btn-sm btn-success voucher"
                    @click="applyVoucher"
                    id="apply-voucher"
                  >
                    Apply
                  </button>
                  <button
                    :disabled="isVoucherApplicable === false ? true : false"
                    class="btn btn-sm btn-danger ml-1 voucher"
                    @click="removeVoucher"
                    id="remove-voucher"
                  >
                    Remove
                  </button>
                </div>
               
              </div>
            
            </div>
          </div>
          <div class="card mobileView" v-if="customerCarId && packageId">
            <div class="col mb-3">
              <h5 class="font-weight-bold mt-3 mb-3">Total Info</h5>
              <div class="table-responsive">
                <table class="table table-borderless mb-0">
                  <tbody v-if="defaultTotal === false && finalDiscountTotal.length === 0">
                    <tr
                      v-if="this.finalTotal.totalExclTax"
                      class="white-space-no-wrap"
                    >
                      <td class="text-muted pl-0">Sub Total</td>
                      <td class="text-monospace">
                        {{
                          this.finalTotal.totalExclTax.amount.toLocaleString(
                            "en-US"
                          ) +
                          " " +
                          this.finalTotal.totalExclTax.currency
                        }}
                      </td>
                    </tr>
                    <tr class="white-space-no-wrap">
                      <td class="text-muted pl-0">Discount</td>
                      <td class="text-monospace" style="width: 3rem">
                        <input
                          min="0"
                          class="custom-input"
                          type="number"
                          id="discountAmount"
                          onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                          v-model="discountAmount"
                          placeholder="0"
                          :maxlength="6"
                          @input="handleDiscountAmount($event)"
                        />
                      </td>
                    </tr>
                    <tr
                      v-if="this.afterDiscountTotal"
                      class="white-space-no-wrap"
                    >
                      <td class="text-muted pl-0">Total (After Discount)</td>
                      <td class="text-monospace">
                        {{
                          this.afterDiscountTotal.toLocaleString("en-US") +
                          " " +
                          this.finalTotal.totalExclTax.currency
                        }}
                      </td>
                    </tr>
                    <tr v-if="this.finalTotal.tax" class="white-space-no-wrap">
                      <td class="text-muted pl-0">Tax</td>
                      <td class="text-monospace">
                        {{
                          this.tax.toLocaleString("en-US") +
                          " " +
                          this.finalTotal.tax.currency
                        }}
                      </td>
                    </tr>
                    <tr
                      v-if="this.finalTotal.totalInclTax"
                      class="white-space-no-wrap"
                    >
                      <td class="text-muted pl-0">Final payable</td>
                      <td class="text-monospace">
                        {{
                          this.totalTaxInclude.toLocaleString("en-US") +
                          " " +
                          this.finalTotal.totalInclTax.currency
                        }}
                      </td>
                    </tr>
                  </tbody>
                   <tbody v-else>
                    <tr
                      v-if="this.finalDiscountTotal"
                      class="white-space-no-wrap"
                    >
                      <td class="text-muted pl-0">Sub Total</td>
                      <td class="text-monospace">
                        {{
                          this.finalDiscountTotal.totalBeforeDiscount.amount.toLocaleString(
                            "en-US"
                          ) +
                          " " +
                          this.finalDiscountTotal.totalBeforeDiscount.currency
                        }}
                      </td>
                    </tr>
                    <tr class="white-space-no-wrap">
                      <td class="text-muted pl-0">Discount</td>
                      <td class="text-monospace"  style="width: 3rem">
                        <input
                          min="0"
                          class="custom-input"
                          type="number"
                          id="discountAmount"
                          onkeyup="value=value.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')"
                          v-model="this.discountAmount"
                          placeholder="0"
                          :disabled="isDiscountDisabled"
                        />

                          <!-- @input="handleAfterVoucherDiscountAmount($event)" -->
                      </td>
                    </tr>
                    <tr v-if="this.finalTaxAfterToTal" class="white-space-no-wrap">
                      <td class="text-muted pl-0">Total (After Discount)</td>
                      <td class="text-monospace">
                        {{
                          this.finalTaxAfterToTal.toLocaleString("en-US") +
                          " " +
                          this.finalDiscountTotal.totalAfterDiscount.currency
                        }}
                      </td>
                    </tr>
                    <tr v-if="this.finalDiscountTotal.finalTax" class="white-space-no-wrap">
                      <td class="text-muted pl-0">Tax</td>
                      <td class="text-monospace">
                        {{
                          this.finalDiscountedTax.toLocaleString("en-US") +
                          " " +
                          this.finalDiscountTotal.finalTax.currency
                        }}
                      </td>
                    </tr>
                    <tr
                      v-if="this.finalDiscountTotal.finalTotalInclTax"
                      class="white-space-no-wrap"
                    >
                      <td class="text-muted pl-0">Total</td>
                      <td class="text-monospace">
                        {{
                          this.finalTotalInclTax.toLocaleString("en-US") +
                          " " +
                          this.finalDiscountTotal.finalTotalInclTax.currency
                        }}
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <input
          class="btn btn-success order-add-btn btn-visible-web btn-md"
          id="order-now"
          type="button"
          @click="handleCreateOrder()"
          value="Add Wash Subscription"
        />
      </div>
    </div>
     <b-modal
      v-model="isVoucherChange"
      id="modal-tax"
      centered
      title="Alert"
      size="md"
      :no-close-on-backdrop="true"
      :close-on-esc="false"
    >
      <div>
        <p>Any vouchers that were applied earlier have been reset, please apply again.</p>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="info"
            size="sm"
            class="float-right btn-success"
            @click="handleYesCancelModal"
          >
            OK
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  GET_CUSTOMERS,
  GET_CUSTOMERS_DETAILS,
  GET_BUILDING_LIST,
  GET_PACKAGE_LIST,
  GET_WASH_SUBS_AVAILABLE_VOUCHERS,
  CHECK__WASH_SUBS_VOUCHERS
} from "../../../graphql/query";
import { WASH_SUBSCRIPTION_CREATE } from "../../../graphql/mutation";

export default {
  name: "OrderNew",
  components: {},
  data() {
    return {
      isLoader: false,
      staffNote: "",
      userId: null,
      carId: null,
      buildingId: null,
      packageId: null,
      discountAmount: null,
      afterDiscountTotal: 0,
      tax: 0,
      tax_rate: 0,
      totalTaxInclude: 0,
      slotDay: [],
      slotTime: [],
      slotTimeArray: [],
      

      finalTotal: [],
      parkingSlot: null,
      users: [],
      cars: [],
      buildings: [],
      packages: [],
      slots: [],
      slotsWeekOptions: [],
      weekDayOptions: [],
      weekTimeOptions: [],
      scheduledSlotsArray: [],
      selectedStates: [],

      first: 10,
      last: null,
      after: "",
      before: "",
      searchText: "",
      emptyCustomerName: false,
      modalShow: false,
      max: 2,
      customerPhone: "",
      customerCarId: "",
      search: null,
      plusSvg: require("../../../assets/images/svg/plus.svg"),
      minusSvg: require("../../../assets/images/svg/minus.svg"),
      isCustomer: false,
      isCar: false,
      isBuilding: false,
      isPackage: false,
      isScheduledSlots: false,
      isParkingSlot: false,

      errors: [],
      invalidDiscountName: false,
      checked: true,
      checkTaxShow: false,
      checkTaxData: false,
      isCash:false,
      checkedIsCash:false,

      chargeAutomatically: true,
      checkedAutoCharge: true,

      voucher_code: null,
      vouchers: [],
      reason: "",
      isVoucherApplicable:false,
      isVoucher:false,
      isVoucherApplied:null,
      defaultTotal:false,
      finalDiscountTotal:[],
      finalTaxBeforeToTal: 0,
      finalTaxAfterToTal: 0,
      finalDiscount:0,
      finalDiscountedTax: 0,
      finalTotalInclTax: 0,
      discountedAmount: 0,
      discountedTax: 0,
      discountedTotalTaxInclude: 0,
      isDiscountDisabled: false,
      isVoucherChange: false,
      voucherName:''
    };
  },
  props: ["orderId"],
  beforeRouteEnter(to, from, next) {
    var phone = localStorage.getItem("customer-phone");
    if (localStorage.getItem("customer-car")) {
      var custCarId = localStorage.getItem("customer-car");
    }
    if (phone) {
      next((vm) => {
        vm.handleSearchCustomer(phone).then(() => {
          vm.onChangeCustomer(vm.users[0]);
          vm.onChangeCar(custCarId);
        });
        vm.search = "";
        vm.getCustomer();
      });
    } else {
      next();
    }
  },
  watch: {
    packageId: function () {
      if (this.packageId !== null) {
        this.getScheduledSlots();
      }
    },
  },

  computed: {
    computedDevices() {
      // filter by conditions here
      return this.slotsWeekOptions.map((el) => {
        this.weekDayOptions.filter((v) => v.weekDay == el.weekDay);
      });
    },
  },

  mounted() {
    this.getCustomer();
    this.getBuildings();
  },
  methods: {
    

    handleStaffNote(e) {
      this.staffNote = e.target.value;
    },
    handleAutoCharge() {
      this.chargeAutomatically = this.checkedAutoCharge;
    },

    validateInput(data) {
      //Regex for Valid Characters i.e. Alphabets, Numbers and Space.
      var regex = /^[0-9.]+$/;

      var isValid = regex.test(data);

      return isValid;
    },
    handleDiscountAmount(e) {
      if (e.target) {
        var test_data = this.validateInput(e.target.value);
        if (test_data) {
          this.discountAmount = e.target.value;
          if (parseFloat(this.discountAmount) >= 0) {
            this.afterDiscountTotal =
              Math.round(
                (this.finalTotal.totalExclTax.amount - parseFloat(this.discountAmount)) * 100
              ) / 100;
            this.tax =
              Math.round((this.tax_rate / 100) * this.afterDiscountTotal * 100) /
              100;
            this.totalTaxInclude =
              Math.round(
                (this.afterDiscountTotal +
                  (this.tax_rate / 100) * this.afterDiscountTotal) *
                  100
              ) / 100;
          }
        }
      }
      else{
        if (parseFloat(e) >= 0) {
            this.afterDiscountTotal =
              Math.round(
                (this.finalTotal.totalExclTax.amount - parseFloat(e)) * 100
              ) / 100;
            this.tax =
              Math.round(
                (this.tax_rate / 100) *
                  this.afterDiscountTotal *
                  100
              ) / 100;
            this.totalTaxInclude =
                Math.round(
                  (this.afterDiscountTotal +
                    (this.tax_rate / 100) * this.afterDiscountTotal) *
                    100
                ) / 100;
          }
      }
    },
    formatTime(timeString) {
      const timeString12hr = new Date(
        "1970-01-01T" + timeString + "Z"
      ).toLocaleTimeString("en-US", {
        timeZone: "UTC",
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      });

      return timeString12hr;
    },

    capitalizeFirstLetter(str) {
      str = str.toLowerCase().replace(/\b[a-z]/g, function (letter) {
        return letter.toUpperCase();
      });

      return str;
    },

    getScheduledSlots() {
      let arr = [];
      let arrayValue = [];
      if (this.packageId.id) {
        this.slots.forEach((val) => {
          let index = arr.findIndex((v) => v.weekDay === val.weekDay);
          if (index > -1) {
            arr[index].options.push({
              label:
                this.formatTime(val.fromTime) +
                " - " +
                this.formatTime(val.toTime),
              fromTime: val.fromTime,
              toTime: val.toTime,
              id: val.id,
            });
          } else {
            arr.push({
              ...val,
              options: [
                {
                  label:
                    this.formatTime(val.fromTime) +
                    " - " +
                    this.formatTime(val.toTime),
                  fromTime: val.fromTime,
                  toTime: val.toTime,
                  id: val.id,
                },
              ],
            });
          }
        });
        let currentPackage = this.packages.find(
          (p) => p.id === this.packageId.id
        );
        if (currentPackage) {
          arrayValue = arr.filter(
            (e, i) => i + 1 <= Number(currentPackage.type.weeklyWashes)
          );
        } else {
          arrayValue = [];
        }
      }
      this.slotsWeekOptions = arrayValue;
      this.weekDayOptions = arr.map((m) => ({
        ...m,
        weekDay: this.capitalizeFirstLetter(m.weekDay),
      }));
      this.slotsWeekOptions.map((el) => {
        this.weekDayOptions.filter((v) => v.weekDay == el.weekDay);
      });
    },

    async getCustomer() {
      await this.$apollo
        .query({
          query: GET_CUSTOMERS,
          variables: {
            first: this.first,
            direction: "DESC",
            field: "DATE_JOINED",
            search: this.search,
          },
        })
        .then((data) => {
          let userData = data.data.bwsDashboardUsers.edges;
          this.users = userData.map((res) => {
            return res.node;
          });
        });
    },

    async getBuildings() {
      await this.$apollo
        .query({
          query: GET_BUILDING_LIST,
          variables: {
            first: 10,
            search: this.searchBuilding
          },
        })
        .then((data) => {
          const buildings = data.data.bwsDashboardBuildings.edges;
          this.buildings = buildings.map((res) => {
            return res.node;
          });
        });
    },

    async getPackages() {
      await this.$apollo
        .query({
          query: GET_PACKAGE_LIST,
          variables: {
            carId: this.customerCarId,
            buildingId: !this.buildingId.id ? this.buildingId : this.buildingId.id,
          },
        })
        .then((data) => {
          this.packages = data.data.bwsDashboardPackages;
        });
    },

    onChangeSchedulingTime(e) {
      this.isScheduling = false;
      this.scheduledAt = e.time;
    },

    onChangeSchedulingDate(e) {
      this.isScheduling = false;
      this.scheduledDate = e.date;
      this.schedulingTimeArr = this.schedulingData.schedule.map((result) => {
        if (e.date === this.formatDateScheduling(result.date)) {
          // return result.hour + " " + result.amPm;
          this.schedulingTime = result.times;
        }
      });
    },

    //Customers
    getCustomCustomer(opt) {
      return opt.firstName + " " + opt.lastName;
    },

    //Buildings
    getCustomBuilding(opt) {
      return opt.name + ' - ' + opt.address;
    },

    handleSearchBuilding(event) {
      if (event) {
        this.searchBuilding = event;
      } else {
        this.searchBuilding = "";
      }
      this.getBuildings();
    },
    
    clearAll () {
      this.buildings = []
    },

    //Packages
    getCustomPackage(opt) {
      return opt.type.name;
    },

    getOptions(id) {
      const option = this.weekDayOptions.find((item) => item.id == id);
      if (option) return option.options;
      return [];
    },

    onSelectedSlotDay(selection, index) {
      this.selectedStates.push({
        id: selection.id,
        values: [],
      });
      this.selectedStates[index].values = null;
    },

    onSelectedSlotTime(selection, index) {
      this.selectedStates[index].id = this.slotDay[index].id;
      this.slotTimeArray[index] = selection.id;
    },

    async handleSearchCustomer(event) {
      if (event) {
        this.search = event;
      } else {
        this.search = "";       
      }
      await this.getCustomer();
    },

    removeCustomer(selectedOption) {
      if (selectedOption) {
        this.customerPhone = null;
        this.before = null;
        this.currentPage = 1;
        this.buildingId = null;
        this.packageId = null;
        this.slotsWeekOptions = null;
        this.slotDay = [];
        this.selectedStates = [];
        this.parkingSlot = null;
        this.getCustomer();
      }
    },

    removeBuilding(selectedOption) {
      if (selectedOption) {
        this.buildingId = null;
        this.packageId = null;
        this.slotsWeekOptions = null;
        this.slotDay = [];
        this.selectedStates = [];
        this.parkingSlot = null;
        this.getBuildings();
      }
    },

    removePackage(selectedOption) {
      if (selectedOption) {
        this.packageId = null;
        this.slotsWeekOptions = null;
        this.slotDay = [];
        this.selectedStates = [];
        this.parkingSlot = null;
        this.finalTotal = [];
        this.getPackages();
      }
    },

    async onChangeCustomer(selection) {
      this.isCustomer = false;
      this.customerCarId = null;
      this.buildingId = null;
      this.packageId = null;
      this.slotsWeekOptions = null;
      this.slotDay = [];
      this.selectedStates = [];
      this.parkingSlot = null;
      this.customerPhone = selection.id;
      localStorage.setItem("customer-phone", this.customerPhone);
      if (selection == null) {
        this.carId = null;
      } else {
        this.userId = selection;
        this.emptyCustomerName = false;
        await this.$apollo
          .query({
            query: GET_CUSTOMERS_DETAILS,
            variables: {
              id: selection.phone ? selection.phone : selection,
              search: this.search,
            },
          })
          .then((data) => {
            this.cars = data.data.bwsDashboardUser.cars;
            var cust_car = localStorage.getItem("customer-car");
            if (cust_car) {
              for (let j = 0; j < this.cars.length; j++) {
                if (this.cars[j].id === cust_car) {
                  return (this.carId = this.cars[j]);
                }
              }
              // localStorage.removeItem("customer-phone");
              // localStorage.removeItem("customer-car");
            }
            // if (this.cars.length === 1) {
            //   this.cars.map((res) => {
            //     this.carId = res;
            //     this.customerCarId = res
            //   });
            // } else
            if (this.cars.length === 0) {
              this.carId = "No cars";
            } else {
              this.carId = null;
            }
          });
      }
    },

    onChangeBuilding(selection) {
      this.isBuilding = false;
      this.buildingId = selection.id;
      this.packageId = null;
      this.slotsWeekOptions = null;
      this.slotDay = [];
      this.selectedStates = [];
      this.parkingSlot = null;
      this.voucher_code = null
      this.isVoucherApplicable = false
      this.isVoucherApplied = null;
      this.defaultTotal = false
      this.finalDiscountTotal = [];
      this.discountAmount = 0
      this.isDiscountDisabled = false
      this.finalTaxAfterToTal = 0
      this.afterDiscountTotal = 0
      if (selection == null) {
        this.buildingId = null;
      } else {
        for (var i = 0; i < this.buildings.length; i++) {
          if (this.buildings[i].id === selection.id) {
            this.slots = this.buildings[i].slots;
            this.tax_rate = this.buildings[i].company.taxRate;
          }
        }
        this.getPackages();
      }
    },

    onChangePackage(selection) {
      this.isPackage = false;
      this.packageId = selection.id;
      this.slotDay = [];
      this.selectedStates = [];
      this.finalTotal = selection.finalTotals;
      this.tax = this.finalTotal.tax.amount;
      this.totalTaxInclude = this.finalTotal.totalInclTax.amount;

      this.isVoucherApplicable = false
      this.isVoucherApplied = null;
      this.defaultTotal = false
      this.finalDiscountTotal = [];
      this.discountAmount = 0
      this.isDiscountDisabled = false
      this.finalTaxAfterToTal = 0
      this.afterDiscountTotal = 0
      if(this.voucher_code){
        this.isVoucherChange = true
        this.voucher_code = null
      }

      if (selection == null) {
        this.packageId = null;
      }

      this.washSubsVouchers()
    },

    handleParkingSlot(e) {
      this.parkingSlot = e.target.value;
      this.isParkingSlot = false;
    },

    //Car
    getCustomCar(opt) {
      if (opt.model) {
        return opt.model.make.name + " " + opt.model.name;
      } else {
        return "No cars for the selected customer. Please add new car";
      }
    },

    async onChangeCar(selection) {
      if (selection.id) {
        this.isCar = false;
        this.buildingId = null;
        this.packageId = null;
        this.slotsWeekOptions = null;
        this.slotDay = [];
        this.selectedStates = [];
        this.parkingSlot = null;
        this.customerCarId = selection.id;
      } else {
        this.isCar = false;
        this.buildingId = null;
        this.packageId = null;
        this.slotsWeekOptions = null;
        this.slotDay = [];
        this.selectedStates = [];
        this.parkingSlot = null;
        this.customerCarId = selection;
      }
    },

    removeCar(selectedOption) {
      if (selectedOption) {
        this.customerCarId = null;
        this.buildingId = null;
        this.packageId = null;
        this.slotsWeekOptions = null;
        this.slotDay = [];
        this.selectedStates = [];
        this.parkingSlot = null;
      }
    },

    handleIsCash() {
      this.isCash = this.checkedIsCash;
    },

    async washSubsVouchers() {
      await this.$apollo
        .query({
          query: GET_WASH_SUBS_AVAILABLE_VOUCHERS,
          variables: {
            buildingId: this.buildingId.id,
            packageId: !this.packageId.id ? this.packageId : this.packageId.id,
          },
        })
        .then((data) => {
          this.vouchers = data.data.washGetAvailableVouchers;
          return this.vouchers
          
        });
    },
    getCustomVoucher(opt) {
      if (opt) {
        return opt.code;
      } else {
        return "No vouchers available for the selected customer";
      }
    },
    onChangeVoucher(selection) {
      if (selection) {
        this.voucher_code = selection.code;
        this.isVoucher = false
      }
    },
    removeVoucher(selectedOption) {
      if (selectedOption) {
        this.voucher_code = null;
        this.washSubsVouchers();
      } else{
        this.voucher_code = null;
      }
      this.isVoucherApplicable = false
      this.isVoucherApplied = null;
      this.defaultTotal = false
      this.finalDiscountTotal = [];
      this.discountAmount = 0
      this.isDiscountDisabled = false
      this.finalTaxAfterToTal = 0
      this.afterDiscountTotal = 0
      // this.handleDiscountAmount(this.discountAmount)
    },
    handleVoucherCode(newTag) {
      const val = {
        code: newTag,
        discountValue: "",
      };
      // this.vouchers.push(val);
      this.voucher_code = val;
      this.isVoucherApplicable = false
    },
    
    applyVoucher() {
      if(this.voucher_code === "" || this.voucher_code === null){
        this.isVoucher = true
      }
      else{
        this.discountAmount = 0
        this.handleDiscountAmount(this.discountAmount)
        this.isDiscountDisabled = true
        this.isVoucher = false
          this.$apollo
            .query({
              query: CHECK__WASH_SUBS_VOUCHERS,
              variables: {
                voucherCode: this.voucher_code.code,
                buildingId: this.buildingId.id,
                packageId: this.packageId.id
              },
            })
            .then((data) => {
              this.reason = data.data.washCheckVoucher.reason;
              if(this.reason){
                this.isVoucherApplied = false;
                this.isVoucherApplicable = true;
                this.defaultTotal = false
                this.finalTaxAfterToTal = 0
                this.afterDiscountTotal = 0;
                // this.voucher_code = null
              }
              else{
                if(data.data.washCheckVoucher.trialPeriod){
                  this.defaultTotal = false
                  this.isVoucherApplicable = true;
                  this.isVoucherApplied = true;
                  this.finalDiscountTotal = []
                  this.finalTaxAfterToTal = 0
                  this.afterDiscountTotal = 0
                  this.voucherName = data.data.washCheckVoucher.name
                }
                else{
                  this.defaultTotal = true
                  this.isVoucherApplicable = true;
                  this.isVoucherApplied = true;
                  this.finalDiscountTotal = data.data.washCheckVoucher
                  this.finalTaxBeforeToTal = data.data.washCheckVoucher.totalBeforeDiscount.amount
                  this.finalTaxAfterToTal = data.data.washCheckVoucher.totalAfterDiscount.amount
                  this.discountAmount =  parseInt(data.data.washCheckVoucher.discount.amount)
                  this.finalDiscountedTax = data.data.washCheckVoucher.finalTax.amount
                  this.finalTotalInclTax = data.data.washCheckVoucher.finalTotalInclTax.amount
                  this.voucherName = data.data.washCheckVoucher.name
                  // this.handleAfterVoucherDiscountAmount(this.discountAmount)
                }
              }
            })
            .catch((errors) => {
              // this.errors.map((e) => {
              if (
                errors.message ==
                "GraphQL error: {'services': [\"Could not resolve to a node with the global id list of '[]'.\"]}"
              ) {
                this.$bvToast.toast(
                  "Voucher is not applicables for given services",
                  {
                    toaster: "b-toaster-top-center",
                    variant: "danger",
                    solid: true,
                    toastClass: "text-center toast-box toast-style",
                  }
                );
              }
            });
      }
      // } else {
      //   this.isVoucherApplied = false;
      //   this.reason = "Voucher is not applicable for given services";
      // }
    },
     handleYesCancelModal() {
      this.isVoucherChange = false;
      this.voucher_code = null;
    },

    handleCreateOrder() {
      if (!this.userId) {
        return (this.isCustomer = true);
      } else if (!this.carId || this.carId === "No cars") {
        return (this.isCar = true);
      } else if (!this.buildingId) {
        return (this.isBuilding = true);
      } else if (!this.packageId) {
        return (this.isPackage = true);
      } else if (
        this.slotTimeArray.length !== this.packageId.type.weeklyWashes
      ) {
        this.$bvToast.toast("Please Choose the Slot Day and Time", {
          toaster: "b-toaster-top-center",
          variant: "danger",
          solid: true,
          toastClass: "text-center toast-box toast-style",
        });
      } else if (!this.parkingSlot) {
        return (this.isParkingSlot = true);
      } else {
        document.getElementById("wash-subs-form").style.display = "none";
        document.getElementById("load-spinner").style.display = "flex";

        var amount, currency;
        if(this.defaultTotal === false || this.finalDiscountTotal.length === 0){
          amount = this.finalTotal.totalInclTax.amount
          currency = this.finalTotal.totalInclTax.currency
        }
        else{
          amount = this.finalTotalInclTax,
          currency = this.finalDiscountTotal.finalTotalInclTax.currency
        }

        this.$apollo
          .mutate({
            mutation: WASH_SUBSCRIPTION_CREATE,
            variables: {
              userId: this.userId.id,
              carId: this.customerCarId,
              buildingId: this.buildingId.id,
              packageId: this.packageId.id,
              parkingSlot: this.parkingSlot,
              scheduledSlots: this.slotTimeArray,
              discount: this.discountAmount ? this.discountAmount : 0,
              amount: amount,
              currency: currency,
              notes: this.staffNote,
              isCash: this.isCash,
              chargeAutomatically: this.chargeAutomatically,
              voucherCode: this.voucher_code ? this.voucher_code.code : null
            },
          })
          .then((data) => {
            if (
              data.data.bwsDashboardOfflineSubscriptionCreate.errors.length == 0
            ) {
              document.getElementById("wash-subs-form").style.display = "flex";
              document.getElementById("load-spinner").style.display = "none";
              var id = data.data.bwsDashboardOfflineSubscriptionCreate.washSubscription.id;
              this.$router.push({ path: "/washsubscription/view/" + id });
              localStorage.removeItem("customer-phone");
              localStorage.removeItem("customer-car");
            } else {
              document.getElementById("wash-subs-form").style.display = "block";
              document.getElementById("load-spinner").style.display = "none";
              this.errors =
                data.data.bwsDashboardOfflineSubscriptionCreate.errors;
              this.errors.map((e) => {
                this.$bvToast.toast(`${e.message}`, {
                  toaster: "b-toaster-top-center",
                  variant: "danger",
                  solid: true,
                  toastClass: "text-center toast-box toast-style",
                });
              });
            }
          })
          .catch((error) => {
            var data_error = error.message.split(":");

            // This should log the error object but is just printing out the message
            this.$bvToast.toast(`${data_error[2]}`, {
              toaster: "b-toaster-top-center",
              variant: "danger",
              solid: true,
              toastClass: "text-center toast-box toast-style",
            });
          });
      }
    },
  },
};
</script>

<style>
.text-monospace {
  font-family: monospace, monospace !important;
  text-align: right;
}
.custom-input {
  display: block;
  width: 100%;
  padding: 0.375em 0.75em;
  font-size: 1em;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25em;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-sizing: border-box;
}

#modal-center-location .modal-footer {
  display: none !important;
}
.b-toast-danger.b-toast-solid .toast.toast-box {
  padding: 13px 34px;
  margin: 0 auto;
}
.wrap {
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
}

#load-spinner {
  justify-content: center;
  display: none;
}

/* .button {
width: 120px;
height: 35px;
font-family: "Roboto", sans-serif;
font-size: 11px;
letter-spacing: 2.5px;
font-weight: 500;
color: #fff;
border: 1px solid rgb(231, 231, 231);
border-radius: 8px;
box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
transition: all 0.2s ease 0s;
cursor: pointer;
outline: none;
}

.button:hover {
box-shadow: 0px 15px 20px rgba(52, 229, 46, 0.4);
color: #fff;
transform: translateY(3px);
} */

.multiselect,
.multiselect__input,
.multiselect__single {
  font-size: 1em !important;
}

.singleOptions .multiselect__tags {
  min-height: 10px !important;
}

.notesArea {
  line-height: 25px !important;
}

.btn-transistion {
  transition: all 0.3s ease 0s;
}
.btn-transistion:hover {
  transform: translateY(3px);
}

.tax-checkbox {
  left: 10px;
  top: 1px;
  position: relative;
}

#modal-tax___BV_modal_header_ button {
  display: none;
}

#tax-disabled-text {
  padding: 16px;
  color: red;
  font-weight: 500;
}

.dropdown-css {
  margin-top: 10px;
}

.remove-voucher-custom{
  font-size: 20px;
  cursor: pointer;
  position: absolute;
  top: 40%;
  right: 12%;
  z-index: 1;
  transform: translate(0%, -50%);
}

.desktopView {
  display: block;
}

.mobileView {
  display: none;
}

.order-add-btn {
  margin-left: 15px;
  margin-bottom: 25px !important;
}

@media only screen and (max-width: 991px) {
  .btn-visible-web {
    display: none;
  }

  .desktopView {
    display: none;
  }

  .mobileView {
    display: flex;
  }
}

@media only screen and (max-width: 767px) {
  .slot-row.row {
    display: block !important;
  }

  .slot-row .time-div {
    margin-top: 1.4rem;
  }

  .text-padding-mobile {
    padding-bottom: 10px;
  }
   .table{
    display: table !important;
  }
}

@media only screen and (min-width: 992px) {
  .btn-visible-mobile {
    display: none;
  }
}
</style>

